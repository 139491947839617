// ** Import from react dom
import { Route, Routes, Navigate } from 'react-router-dom';

// ** Import core SCSS styles
import 'assets/scss/theme.scss';

import NotFound from 'layouts/marketing/NotFound';
import ComingSoon from 'components/ComingSoon';
import Error404 from 'components/Error404';

const AllRoutes = () => {
	return (
		<Routes>
			{/* Routes with NotFound */}
			<Route element={<NotFound />}>
				<Route
					path="/"
					element={<ComingSoon />}
				/>
				<Route path="/404-error" element={<Error404 />} />
			</Route>

			{/*Redirect*/}
			<Route
				path="*"
				element={<Navigate to="/404-error" replace />}
			/>
		</Routes>
	);
};

export default AllRoutes;
